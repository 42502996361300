import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"
import blogStyles from "./blog.module.scss"
const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1 className={blogStyles.newTitle}>About Me</h1>
      <p className={blogStyles.newTitle}>
        I currently full-time Front-end Developer And Teaching Free video
        Course's.
      </p>
      <p className={blogStyles.newTitle}>
        <Link to="/contact">Want to work with me? Reach out.</Link>
      </p>
    </Layout>
  )
}

export default AboutPage
